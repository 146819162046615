import {
  FC,
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  useId,
  useRef,
  useState,
} from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import Download03 from 'src/assets/svgicons/duocolor/download-03.svg';
import InfoCircle from 'src/assets/svgicons/duocolor/info-circle.svg';
import Share04 from 'src/assets/svgicons/duocolor/share-04.svg';
import DotsHorizontal from 'src/assets/svgicons/solid/dots-horizontal.svg';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import BaseCard from 'src/components/BaseCard/BaseCard';
import BaseCardHeader from 'src/components/BaseCard/BaseCardHeader';
import { AriaButton } from 'src/components/Button/Button';
import { CommentButton } from 'src/components/Comments/CommentButton';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { trpc } from 'src/lib/trpc';
import { InsightsMedia } from 'src/pages/Insights/components/InsightsMedia/InsightsMedia';
import {
  GetFacebookAdsStatisticsResponse,
  GetFacebookAdWithInsightsResponse,
} from 'src/types/insights';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import {
  selectedInsightsAdsAtom,
  toggleInsightsAdsAtom,
} from '../../Insights.atoms';
import { useInsightsAdAccount } from '../../util/useInsightsQueries';
import ShareInsightsAdModal from '../ShareInsightsAdModal';
import { InsightsCardMetrics } from './components/InsightsCardInsights/InsightsCardMetrics';
import { InsightsCardStatus } from './components/InsightsCardStatus/InsightsCardStatus';

type Props = {
  ad: GetFacebookAdWithInsightsResponse;
  showAdDetailsModal: (value: {
    ad: GetFacebookAdWithInsightsResponse;
  }) => void;
  statistics: GetFacebookAdsStatisticsResponse | null;
  currency: string;
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
} & PropsWithChildren;

export const InsightsCard: FC<Props> = ({
  ad,
  showAdDetailsModal,
  statistics,
  currency,
  customConversions,
  customEvents,
}) => {
  const { LL } = useI18nContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { accountUuid } = useParams();
  const { adAccount } = useInsightsAdAccount({ accountUuid });
  const [showShareModal, setShowShareModal] = useState(false);

  const insightsOrganisationAdAccountFacebookUuid =
    adAccount.data?.insightsOrganisationAdAccountUuid;

  const insightsOrganisationAd =
    trpc.insightsAds.getInsightsOrganisationAdFacebook.useQuery(
      {
        insightsOrganisationAdAccountFacebookUuid:
          insightsOrganisationAdAccountFacebookUuid ?? '',
        insightsAdFacebookUuid: ad.uuid,
      },
      {
        enabled: !!insightsOrganisationAdAccountFacebookUuid,
      }
    );

  const handleSelect = (shiftSelectMode: boolean = false) => {
    if (shiftSelectMode) {
      return;
    }

    showAdDetailsModal({ ad });
  };

  const [isActionsActive, setIsActionsActive] = useState(false);

  const onContainerClick = (event: MouseEvent<HTMLDivElement>) => {
    if (
      // Check that the element that was clicked was either our root containing div, or its first inner div
      (containerRef.current === event.target ||
        containerRef.current?.firstChild === event.target) &&
      !event.shiftKey
    ) {
      handleSelect();
    } else if (
      event.shiftKey &&
      containerRef.current?.contains(event.target as EventTarget & HTMLElement)
    ) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();

      toggleInsightsAd(ad.uuid);
    } else if (
      (event.target as EventTarget & HTMLElement).tagName === 'IMG' &&
      containerRef.current?.contains(event.target as EventTarget & HTMLElement)
    ) {
      // Account for the fact that users will likely click on an ad image asset to expand the ad
      handleSelect();
    }
  };

  const onHeaderClick = () => {
    handleSelect();
  };

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (containerRef.current === event.target && event.key === 'Enter') {
      // Important, as by default, the enter key causes both click *and* key events to fire!
      event.preventDefault();
      handleSelect();
    }
  };

  const searchParams = new URLSearchParams(location.search);
  searchParams.set('insightsUuid', ad.uuid);
  const href = `?${searchParams.toString()}`;

  const mediaId = useId();

  // As Insights doesn't currently support multiple assets, we don't have to zip the assets.
  const getAssetDownloadUrl = () => {
    const asset = ad.creative?.assets?.[0];
    switch (asset?.mediaType) {
      case 'image':
        return asset.cachedUrl ?? asset.cachedThumbnailUrl ?? '';
      case 'video':
        return asset.cachedUrl ?? '';
      default:
        return '';
    }
  };

  const toggleInsightsAd = useSetAtom(toggleInsightsAdsAtom);
  const selectedInsightsAds = useAtomValue(selectedInsightsAdsAtom);

  const isAdSelected = selectedInsightsAds.includes(ad.uuid);

  return (
    <>
      <BaseCard
        containerRef={containerRef}
        onContainerClick={onContainerClick}
        onClickCapture={(e: React.MouseEvent<HTMLDivElement>) => {
          if (e.shiftKey && ad.type === 'ad') {
            e.stopPropagation();
            e.preventDefault();
            toggleInsightsAd(ad.uuid);
          }
        }}
        onKeyDown={onKeyDown}
        cardContentClassName="relative gap-2"
        selected={isAdSelected}
      >
        <div className="absolute right-6 top-6 z-[3] flex rounded-lg bg-white shadow-2xl group-hover:border group-hover:border-solid group-hover:border-purple-200">
          {insightsOrganisationAd.data && (
            <CommentButton
              parentEntityType="InsightsOrganisationAdFacebook"
              parentUuid={insightsOrganisationAd.data.uuid}
              readonly={false}
            />
          )}

          <AriaMenu onOpenChange={(isOpen) => setIsActionsActive(isOpen)}>
            <AriaButton
              className={cn(
                'hidden group-hover:inline-flex',
                isActionsActive && 'inline-flex'
              )}
              flat
              variant="text"
              icon={
                <Icon>
                  <DotsHorizontal />
                </Icon>
              }
            />
            <AriaMenu.List
              onAction={(key) => {
                if (key === 'share') {
                  setShowShareModal(true);
                }
              }}
            >
              <AriaMenu.Item
                href={href}
                icon={
                  <Icon>
                    <InfoCircle />
                  </Icon>
                }
              >
                {LL.ad.viewDetails()}
              </AriaMenu.Item>

              {ad.creative?.assets && !!getAssetDownloadUrl() && (
                <DownloadAssetMenuItem
                  url={getAssetDownloadUrl() ?? ''}
                  adUuid={ad.uuid}
                  brandName={ad.name}
                />
              )}

              {insightsOrganisationAdAccountFacebookUuid && (
                <AriaMenu.Item
                  id="share"
                  icon={
                    <Icon>
                      <Share04 />
                    </Icon>
                  }
                >
                  {LL.insights.card.menu.share()}
                </AriaMenu.Item>
              )}
            </AriaMenu.List>
          </AriaMenu>
        </div>

        {!!ad.creative && (
          <InsightsMedia
            display="grid"
            adUuid={ad.uuid}
            creativeType={ad.creative?.creativeType}
            assets={ad.creative?.assets ?? []}
            mediaId={mediaId}
            isPreview
          />
        )}

        <BaseCardHeader
          onClick={onHeaderClick}
          title={
            <span className="flex flex-row items-center gap-2">
              {ad.platform === 'facebook' && !!ad.effectiveStatus ? (
                <InsightsCardStatus effectiveStatus={ad.effectiveStatus} />
              ) : (
                <div className="flex items-center gap-1 uppercase">
                  {ad.delivery}
                </div>
              )}
              <span className="truncate">{ad.name}</span>
            </span>
          }
        />
        <InsightsCardMetrics
          customEvents={customEvents}
          customConversions={customConversions}
          currency={currency}
          statistics={statistics}
          metrics={ad.metrics}
          mediaId={mediaId}
        />
      </BaseCard>
      <ShareInsightsAdModal
        show={showShareModal}
        onClose={() => setShowShareModal(false)}
        adUuid={ad.uuid}
        adName={ad.name}
      />
    </>
  );
};

type DownloadAssetMenuItemProps = {
  url: string;
  adUuid: string;
  brandName: string;
};

const DownloadAssetMenuItem = ({
  url,
  adUuid,
  brandName,
}: DownloadAssetMenuItemProps) => {
  const { LL } = useI18nContext();
  const { recordEvent } = useNewAnalyticsEvent();

  if (url == null) return null;

  return (
    <AriaMenu.Item
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onAction={() =>
        void recordEvent({
          target: 'Insights Ad',
          action: 'Downloaded asset',
          metadata: {
            uuid: adUuid,
            brand_name: brandName,
          },
        })
      }
      icon={
        <Icon>
          <Download03 />
        </Icon>
      }
    >
      {LL.insights.card.menu.downloadAsset()}
    </AriaMenu.Item>
  );
};
