import { useState } from 'react';
import { Button } from 'react-aria-components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import ChevronRight from 'src/assets/svgicons/line/chevron-right.svg';
import Plus from 'src/assets/svgicons/line/plus.svg';
import DotsHorizontal from 'src/assets/svgicons/line/dots-horizontal.svg';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import Trash01 from 'src/assets/svgicons/line/trash-01.svg';
import Share04 from 'src/assets/svgicons/line/share-04.svg';
import Pencil01 from 'src/assets/svgicons/line/pencil-01.svg';
import Move from 'src/assets/svgicons/line/arrow-right.svg';
import FilePlus01 from 'src/assets/svgicons/duotone/file-plus-01.svg';
import { useI18nContext } from 'src/i18n/i18n-react';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import CreatePackModal from 'src/pages/LibraryV2/routes/Packs/components/CreatePackModal';
import FeaturesModal from 'src/components/Modals/FeaturesModal';
import { Icon } from '../../Icon';
import {
  useInitiateDeleteItemFromDirectory,
  useInitiateEditDirectory,
  useInitiateMoveNodeToDirectory,
  useInitiateNewDirectory,
  useInitiateShareDirectory,
} from '../useDirectoryActions';

export const DirectoryAccordion: React.FC<
  React.PropsWithChildren<{
    isSelected?: boolean;
    isDisabled?: boolean;
    hasChildDirectories: boolean;
    label: React.ReactNode;
    level: number;
    to?: string;
    /** null uuid indicates the root */
    uuid: string | null;
    showMenu: boolean;
    name: string;
    query?: string;
    parentDirectoryNodeUuid: string | null;
  }>
> = ({
  level,
  isSelected,
  hasChildDirectories,
  label,
  name,
  children,
  to,
  isDisabled,
  uuid,
  showMenu,
  query,
  parentDirectoryNodeUuid,
}) => {
  const { LL } = useI18nContext();
  const openNewDirectoryModal = useInitiateNewDirectory();
  const openDeleteModal = useInitiateDeleteItemFromDirectory();
  const openEditModal = useInitiateEditDirectory();
  const openShareModal = useInitiateShareDirectory();
  const openMoveModal = useInitiateMoveNodeToDirectory();
  const user = useUserAndOrganisation();
  const [showCreatePackModal, setShowCreatePackModal] = useState(false);
  const [showFeaturesModal, setShowFeaturesModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(uuid === null);

  const style: React.CSSProperties = {
    marginLeft: `${(Math.min(level, 3) + 1) * 8}px`,
  };

  const isQueryMatch = query
    ? name.toLocaleLowerCase().includes(query?.toLocaleLowerCase())
    : true;
  const isTrialExpired =
    user.data?.organisation.billingState === 'trialExpired';

  return (
    <div className="flex flex-col">
      {isQueryMatch && (
        <div
          className={classNames(
            'group relative mb-0.5 flex h-8 cursor-pointer flex-row items-center rounded-lg text-sm leading-5 text-purple-800 outline-none hover:bg-purple-100',
            isSelected ? 'bg-purple-100' : 'bg-none'
          )}
        >
          {hasChildDirectories && (
            <Button
              onPress={() => setIsExpanded((s) => !s)}
              className="group h-8 w-8 px-1.5 outline-none disabled:cursor-not-allowed"
            >
              <span className="flex h-5 w-5 items-center justify-center rounded bg-none text-text-secondary group-hover:bg-purple-100">
                <Icon>
                  {isExpanded ? (
                    <ChevronDown className="h-2.5 w-2.5" />
                  ) : (
                    <ChevronRight className="h-2.5 w-2.5" />
                  )}
                </Icon>
              </span>
            </Button>
          )}
          {to ? (
            <Link
              to={to}
              className={classNames(
                'h-full flex-auto truncate rounded-r-lg px-1.5 align-middle leading-8',
                isSelected && 'font-semibold'
              )}
            >
              {label}
            </Link>
          ) : (
            <span
              className={classNames(
                'h-full flex-auto truncate rounded-r-lg px-1.5 align-middle leading-8',
                isSelected && 'font-semibold',
                isDisabled && 'cursor-not-allowed text-gray-500'
              )}
            >
              {label}
            </span>
          )}
          <div className="flex gap-1 pr-2 opacity-0 group-hover:opacity-100">
            {showMenu && !!uuid && (
              <AriaMenu>
                <Button className="group/add-dir flex items-center justify-center rounded outline-none">
                  <span className="flex h-5 w-5 items-center justify-center rounded bg-none text-text-secondary group-hover/add-dir:bg-purple-200">
                    <Icon className="h-4 w-4">
                      <DotsHorizontal />
                    </Icon>
                  </span>
                </Button>
                <AriaMenu.List
                  onAction={(key) => {
                    switch (key) {
                      case 'delete': {
                        openDeleteModal({
                          directoryNodeUuid: uuid,
                          entityName: name,
                        });
                        break;
                      }
                      case 'share': {
                        openShareModal({
                          directoryNodeUuid: uuid,
                          entityName: name,
                        });
                        break;
                      }
                      case 'move': {
                        openMoveModal({
                          directoryNodeUuids: [uuid],
                          entityName: name,
                          originDirectoryNodeUuid: parentDirectoryNodeUuid,
                        });
                        break;
                      }
                      case 'edit': {
                        openEditModal({
                          directoryUuid: uuid,
                          name: name,
                        });
                        break;
                      }
                      case 'new-ad-pack': {
                        setShowCreatePackModal(true);
                        break;
                      }
                    }
                  }}
                >
                  <AriaMenu.Item
                    id="share"
                    icon={
                      <Icon>
                        <Share04 />
                      </Icon>
                    }
                  >
                    {LL.share()}
                  </AriaMenu.Item>
                  <AriaMenu.Item
                    id="move"
                    icon={
                      <Icon>
                        <Move />
                      </Icon>
                    }
                  >
                    Move to Board
                  </AriaMenu.Item>
                  <AriaMenu.Item
                    id="edit"
                    icon={
                      <Icon>
                        <Pencil01 />
                      </Icon>
                    }
                  >
                    {LL.rename()}
                  </AriaMenu.Item>
                  {user.data?.user.undiesUser && (
                    <AriaMenu.Item
                      id="new-ad-pack"
                      icon={
                        <Icon>
                          <FilePlus01 />
                        </Icon>
                      }
                    >
                      {LL.newAdPack()}
                    </AriaMenu.Item>
                  )}
                  <AriaMenu.Item
                    danger
                    id="delete"
                    icon={
                      <Icon>
                        <Trash01 />
                      </Icon>
                    }
                  >
                    {LL.delete()}
                  </AriaMenu.Item>
                </AriaMenu.List>
              </AriaMenu>
            )}
            <Button
              onPress={() =>
                openNewDirectoryModal({ parentDirectoryUuid: uuid })
              }
              className="group/add-dir flex items-center justify-center rounded outline-none"
            >
              <span className="flex h-5 w-5 items-center justify-center rounded bg-none text-text-secondary group-hover/add-dir:bg-purple-200">
                <Icon className="h-4 w-4">
                  <Plus />
                </Icon>
              </span>
            </Button>
          </div>
        </div>
      )}
      <div style={style}>
        {(isExpanded || !!query) && (
          <div className="flex flex-col">{children}</div>
        )}
      </div>
      {user.data?.user.undiesUser && uuid && (
        <CreatePackModal
          directoryUuid={uuid}
          show={showCreatePackModal}
          onClose={() => setShowCreatePackModal(false)}
        />
      )}
      {isTrialExpired && (
        <button
          className="absolute inset-0 outline-none"
          onClick={() => setShowFeaturesModal(true)}
        />
      )}
      <FeaturesModal
        isOpen={showFeaturesModal}
        close={() => setShowFeaturesModal(false)}
        target="Paywall Sidebar"
      />
    </div>
  );
};
